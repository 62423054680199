import type { AxiosPromise, MessageThreadResponse } from '$lib/types/response';
import { ai8Api, taigaApi } from '$src/axios/api';

const MESSAGE_THREAD_API_BASE_ROUTE = 'message-thread/';
const TAIGA_MESSAGE_THREAD_API_BASE_ROUTE = 'my/message-threads';
const MESSAGE_THREAD_FILE_API_BASE_ROUTE = 'message-thread-file/';

const messageThreadRoute = (route?: string) => {
  return MESSAGE_THREAD_API_BASE_ROUTE + (route || '');
};

const messageThreadFileRoute = (route?: string) => {
  return MESSAGE_THREAD_FILE_API_BASE_ROUTE + (route || '');
};

export function getMessageThreads(route: string) {
  return ai8Api.get(messageThreadRoute(route));
}

export function createMessageThread(data: { sym: string }) {
  return ai8Api.post(messageThreadRoute(), data);
}

export function updateTitleMessageThread(id: string, data: { title: string }) {
  return ai8Api.patch(messageThreadRoute(`${id}/`), data);
}

export function deleteMessageThread(id: string) {
  return ai8Api.delete(messageThreadRoute(id));
}

export function getFileUploadURL(messageThreadId: string, filename: string) {
  const params = new URLSearchParams();
  params.append('filename', filename);
  return ai8Api.get(messageThreadRoute(`${messageThreadId}/file-upload-url/`), {
    params,
  });
}

export function createFile(file: string, messageThreadId: string) {
  return ai8Api.post(messageThreadFileRoute(), {
    file: file,
    message_thread_id: messageThreadId,
  });
}

export function getTaigaMessageThread(projectId: string): AxiosPromise<MessageThreadResponse> {
  return taigaApi.get(`${TAIGA_MESSAGE_THREAD_API_BASE_ROUTE}/${projectId}`);
}

export function createTaigaMessageThread(projectId: string): AxiosPromise<MessageThreadResponse> {
  return taigaApi.post(`${TAIGA_MESSAGE_THREAD_API_BASE_ROUTE}/${projectId}`);
}
