<script lang="ts">
  import Ai8Icon from '$lib/assets/icons/ai8-icon.svg';
  import { handleChatMenuOpen } from '$lib/stores/symStore';
  import { BarsSolid } from 'svelte-awesome-icons';

  export let title: string;
</script>

<nav class="flex w-full min-h-[4vh] p-2 justify-center items-center bg-white dark:bg-ai8-chat-gray">
  <h1 class="font-bold text-xl/[22px]">{title}</h1>
</nav>
<nav
  class="relative mobile-navbar dark:mobile-navbar-dark flex flex-row w-full min-h-[6vh] p-2 justify-between items-center bg-white dark:bg-ai8-chat-gray"
>
  <button on:click={handleChatMenuOpen}>
    <BarsSolid class="w-[5vw]" />
  </button>
  <img src={Ai8Icon} alt="ai8-icon" class="w-[8vw]" />
</nav>
