<script lang="ts">
  import { FEEDBACK } from '$lib/utils/constants';
  export let message: ChatMessage;
  export let disabled = false;

  import type { ChatMessage } from '$lib/types/message';
  import type { MessageFeedbackUpdateRequest } from '$lib/types/request';
  import { isSmallScreen } from '$lib/utils/window';
  import { createEventDispatcher } from 'svelte';
  import { ClipboardRegular, ThumbsDownRegular, ThumbsUpRegular } from 'svelte-awesome-icons';

  let smallScreen = isSmallScreen();

  const dispatch = createEventDispatcher();

  function messageFeedbackHandler(feedback: string) {
    message.feedback =
      message.feedback === feedback
        ? feedback === FEEDBACK.LIKED
          ? FEEDBACK.UNDO_LIKED
          : FEEDBACK.UNDO_DISLIKED
        : feedback;
    dispatch('feedbackChange', {
      timestamp: message.timestamp,
      feedback: message.feedback,
    } as MessageFeedbackUpdateRequest);
  }

  async function messageCopyHandler() {
    await navigator.clipboard.writeText(message.message);
  }
</script>

<button class="mx-1 dark:text-gray-300" disabled={message.showActions === false} on:click={messageCopyHandler}>
  <ClipboardRegular size="15" />
</button>
<button
  class="mx-2 {message.feedback === FEEDBACK.LIKED ? 'text-[#fc1b3d]' : 'dark:text-gray-300'}"
  disabled={message.showActions === false || disabled}
  on:click={() => messageFeedbackHandler(FEEDBACK.LIKED)}><ThumbsUpRegular size="15" /></button
>
<button
  class="ml-1 {smallScreen ? '' : 'mr-5'} {message.feedback === FEEDBACK.DISLIKED
    ? 'text-[#fc1b3d]'
    : 'dark:text-gray-300'}"
  disabled={message.showActions === false || disabled}
  on:click={() => messageFeedbackHandler(FEEDBACK.DISLIKED)}><ThumbsDownRegular size="15" /></button
>
