<script lang="ts">
  import ClearIcon from '$lib/assets/icons/clear-icon.svg';
  import FileIcon from '$lib/components/generic/icons/FileIcon.svelte';
  import { UploadStatus } from '$lib/enums/file';
  import type { SymFile } from '$lib/types/file';
  import { createEventDispatcher } from 'svelte';

  export let files: SymFile[] = [];
  const dispatch = createEventDispatcher();
  const getIconColor = (file: SymFile) => {
    switch (file.status) {
      case UploadStatus.Uploaded:
        return 'green';
      case UploadStatus.Uploading:
        return 'blue';
      default:
        return 'red';
    }
  };
</script>

<div class="flex max-h-8 overflow-x-auto overflow-y-hidden my-1">
  {#each files as file (file.name)}
    <div class="flex justify-center items-center border border-gray-300 p-1 rounded-md mr-1">
      <FileIcon color={getIconColor(file)} />
      <p class="truncate">{file.name}</p>
      <button class="p-1 text-red-500" on:click={() => dispatch('fileDelete', file.id)}>
        <img src={ClearIcon} alt="clear-icon.svg" />
      </button>
    </div>
  {/each}
</div>
