export const createChangeTitleSocket = (
  url: string,
  userId: string,
  sessionId: string,
  message: string,
  setTitle: Function
) => {
  const newSocket = new WebSocket(url);

  newSocket.onopen = function (e) {
    const input = {
      action: 'sym',
      user_id: userId,
      session_id: sessionId,
      message: message,
      request_type: 'conv_title_gen',
      response_type: 'complete',
      context: '',
      source: window.location.hostname ?? '',
    };
    newSocket.send(JSON.stringify(input));
  };

  newSocket.onmessage = function (event) {
    const data = JSON.parse(event.data);
    let title = data.body.content;
    if (title.length > 50) {
      title = title.substring(0, 47) + '...';
    }
    setTitle(sessionId, title);
    newSocket.close();
  };
};
