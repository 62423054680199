<script lang="ts">
  import { getUserProfile } from '$lib/api/auth';
  import { getAllSyms } from '$lib/api/chat';
  import {
    createMessageThread,
    deleteMessageThread,
    getMessageThreads,
    updateTitleMessageThread,
  } from '$lib/api/messageThread';
  import ModalWrapper from '$lib/components/generic/ModalWrapper.svelte';
  import ChatMenu from '$lib/components/sym/ChatMenu.svelte';
  import ChatSection from '$lib/components/sym/ChatSection.svelte';
  import CreateMessageThreadModal from '$lib/components/sym/CreateMessageThreadModal.svelte';
  import EditMessageThreadModal from '$lib/components/sym/EditMessageThreadModal.svelte';
  import NavbarMobile from '$lib/components/sym/navbar/NavbarMobile.svelte';
  import symStore, { closeChatMenu } from '$lib/stores/symStore';
  import userStore from '$lib/stores/userStore';
  import type { MessageThreadListResponse, SymResponse, User, UserProfileResponse } from '$lib/types/response';
  import { USER_PROFILE_FETCH_COOLDOWN_SECONDS } from '$lib/utils/constants';
  import { createChangeTitleSocket } from '$lib/utils/websocket';
  import { isSmallScreen } from '$lib/utils/window';
  import { env } from '$src/env';
  import { onDestroy, onMount } from 'svelte';
  import Drawer from 'svelte-drawer-component';

  let selectedSym: SymResponse | null = null;
  let symsEnabled: boolean = true;
  let availableSyms: SymResponse[] = [];
  let allSyms: SymResponse[] = [];
  let interval: any;
  let messageThreads: MessageThreadListResponse[] = [];
  let pageCursor: string | null = null;
  let filter: Record<string, string> = { sym: '' };
  let showNewMessageThreadDialog: boolean = false;
  let showEditMessageThreadDialog: boolean = false;
  let showDeleteMessageThreadDialog: boolean = false;
  let modalSymTitleId: string;
  let selectedSessionId: string = '';
  let isSmallDisplay = isSmallScreen();

  const ACTIONS: Record<string, (e: CustomEvent, isOpen: boolean) => void> = {
    delete: (e, isOpen) => {
      showDeleteMessageThreadDialog = isOpen;
      modalSymTitleId = showDeleteMessageThreadDialog ? e.detail.symId : '';
    },
    edit: (e, isOpen) => {
      showEditMessageThreadDialog = isOpen;
      modalSymTitleId = showEditMessageThreadDialog ? e.detail.symId : '';
    },
    create: (_, isOpen) => {
      showNewMessageThreadDialog = isOpen;
    },
  };

  function handleModalClick(e: CustomEvent, action: string, isOpen: boolean) {
    ACTIONS[action](e, isOpen);
  }

  function resetInterval() {
    clearInterval(interval);
    interval = setInterval(() => {
      loadUserProfile();
    }, USER_PROFILE_FETCH_COOLDOWN_SECONDS * 1000);
  }

  onMount(async () => {
    await loadUserProfile();
    await loadSyms();
    await fetchMessageThreads(true);

    resetInterval();
  });

  onDestroy(() => {
    clearInterval(interval);
  });

  async function fetchMessageThreads(loadFirst = false) {
    try {
      const cursor = pageCursor ?? '';
      const searchParams = new URLSearchParams({ cursor });
      Object.entries(filter).forEach(([k, v]) => searchParams.append(k, v));
      const { data, response } = await getMessageThreads(`?${searchParams.toString()}`);

      if (response.status === 200) {
        const { results, next, previous } = data;
        pageCursor = next ? new URLSearchParams(new URL(next).search).get('cursor') : null;
        messageThreads = !previous ? [...results] : [...messageThreads, ...results];
        if (messageThreads.length <= 0 || !loadFirst) {
          return;
        }
        handleOpenMessageThread(null, messageThreads[0].id, messageThreads[0].sym);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadSyms() {
    let response = await getAllSyms();
    allSyms = response.data as SymResponse[];
  }

  async function loadUserProfile() {
    let response = await getUserProfile();
    let profile = response.data as UserProfileResponse;

    if (profile.syms_enabled) {
      availableSyms = profile.available_syms.filter((sym) => sym?.is_enabled);
      symsEnabled = true;
    } else {
      availableSyms = [];
      symsEnabled = false;
      selectedSym = null;
    }
  }

  function handleLoadMore() {
    fetchMessageThreads();
  }

  function handleSelectSymFilter(e: CustomEvent<{ symId: string }>) {
    const symId = e.detail.symId;
    messageThreads = [];
    pageCursor = null;
    if (symId === '') {
      filter = { sym: '' };
    } else {
      const selectedSym = allSyms.find((s) => s.name === symId) as SymResponse;
      selectedSym.is_enabled = availableSyms.some((s) => s.name === selectedSym.name);
      filter = { sym: selectedSym.name };
    }
    fetchMessageThreads();
  }

  async function handleCreateNewThread(e: CustomEvent) {
    try {
      const { data, response } = await createMessageThread({ sym: e.detail.sym });

      if (response.status) {
        messageThreads = [
          {
            id: data.id,
            title: '',
            created_at: data.created_at,
            sym: e.detail.sym,
            sym_display_name: e.detail.sym_display_name,
          },
          ...messageThreads,
        ];
        showNewMessageThreadDialog = false;
        e.detail.symId = data.id;
        e.detail.symName = e.detail.sym;
        handleOpenMessageThread(e);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleDeleteMessageThread() {
    try {
      const { response } = await deleteMessageThread(modalSymTitleId);

      if (response.status === 204) {
        messageThreads = messageThreads.filter((msgThread) => msgThread.id !== modalSymTitleId);
      }
      showDeleteMessageThreadDialog = false;
    } catch (error) {
      console.error(error);
    }
  }

  async function handleRenameMessageThreadTitle(e: CustomEvent | null) {
    try {
      setTitle(e?.detail?.symId, e?.detail.title);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleEditMessageThreadTitle(e: CustomEvent | null) {
    try {
      if (messageThreads.find((m) => m.id === selectedSessionId)?.title) {
        return;
      }
      createChangeTitleSocket(
        env.symChatWsUrl,
        ($userStore.user as User)?.id.toString() ?? '',
        e?.detail?.symId,
        e?.detail.message,
        setTitle
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function setTitle(symId: string, title: string) {
    const { data, response } = await updateTitleMessageThread(symId ?? modalSymTitleId, {
      title: title,
    });

    if (response.status === 200) {
      messageThreads = messageThreads.map((msgThread) => {
        if (msgThread.id !== symId && msgThread.id !== modalSymTitleId) {
          return msgThread;
        }

        return { ...msgThread, title: data.title };
      });
      messageThreads = [...messageThreads];
    }

    showEditMessageThreadDialog = false;
  }

  function handleOpenMessageThread(e: CustomEvent | null, symId?: string, symName?: string) {
    selectedSessionId = symId ?? e?.detail.symId;
    selectedSym = allSyms.find((s) => s.name === (symName ?? e?.detail.symName)) as SymResponse;
    if (!selectedSym) {
      return;
    }
    selectedSym.is_enabled = availableSyms.some((s) => s.name === selectedSym?.name);
  }
</script>

<div class="h-full w-full flex flex-col text-sm">
  {#if isSmallDisplay}
    <NavbarMobile title="Sym" />
  {/if}
  <div class="{isSmallDisplay ? 'h-[92vh] overflow-hidden' : 'h-full'} w-full">
    <div
      class="h-full w-full bg-ai8-white flex {isSmallDisplay ? 'mobile-navbar relative' : 'px-0'} dark:bg-ai8-chat-gray"
    >
      {#if isSmallDisplay}
        <Drawer open={$symStore?.isChatMenuOpen} size="65%" placement="left" on:clickAway={closeChatMenu}>
          <div class={'p-2 bg-white dark:bg-ai8-chat-gray dark:border-ai8-sym-chat-gray dark:border h-full'}>
            <ChatMenu
              {availableSyms}
              {messageThreads}
              {pageCursor}
              on:newMessageThreadButtonClick={(e) => handleModalClick(e, 'create', true)}
              on:selectSymFilter={handleSelectSymFilter}
              on:loadMore={handleLoadMore}
              on:deleteMessageThread={(e) => handleModalClick(e, 'delete', true)}
              on:editMessageThreadTitle={(e) => handleModalClick(e, 'edit', true)}
              on:openMessageThread={handleOpenMessageThread}
            />
          </div>
        </Drawer>
      {:else}
        <ChatMenu
          {availableSyms}
          {messageThreads}
          {pageCursor}
          on:newMessageThreadButtonClick={(e) => handleModalClick(e, 'create', true)}
          on:selectSymFilter={handleSelectSymFilter}
          on:loadMore={handleLoadMore}
          on:deleteMessageThread={(e) => handleModalClick(e, 'delete', true)}
          on:editMessageThreadTitle={(e) => handleModalClick(e, 'edit', true)}
          on:openMessageThread={handleOpenMessageThread}
        />
      {/if}
      <div class="{$symStore.isChatMenuOpen ? 'md:w-[83%]' : 'w-full'} dark:bg-ai8-sym-chat-dark-gray">
        {#if selectedSessionId}
          <ChatSection
            on:handleEditMessageThreadTitle={handleEditMessageThreadTitle}
            {selectedSessionId}
            {selectedSym}
            isChatEnabled={!symsEnabled || (selectedSym !== null && !selectedSym?.is_enabled)}
          />
        {/if}
      </div>
      {#if showNewMessageThreadDialog}
        <CreateMessageThreadModal
          on:close={(e) => handleModalClick(e, 'create', false)}
          on:confirm={handleCreateNewThread}
          isOpen={showNewMessageThreadDialog}
          {availableSyms}
        />
      {/if}
      {#if showEditMessageThreadDialog}
        <EditMessageThreadModal
          on:close={(e) => handleModalClick(e, 'edit', false)}
          on:confirm={handleRenameMessageThreadTitle}
          isOpen={showEditMessageThreadDialog}
        />
      {/if}
      {#if showDeleteMessageThreadDialog}
        <ModalWrapper
          on:close={(e) => handleModalClick(e, 'delete', false)}
          on:confirm={handleDeleteMessageThread}
          isOpen={showDeleteMessageThreadDialog}
          title={'Confirm deletion'}
        />
      {/if}
    </div>
  </div>
</div>
