<script lang="ts">
  import ModalWrapper from '$lib/components/generic/ModalWrapper.svelte';
  import { createEventDispatcher } from 'svelte';
  let symTitle: string = '';
  export let isOpen: boolean = false;

  const dispatch = createEventDispatcher();

  function handleConfirm() {
    dispatch('confirm', { title: symTitle });
  }
</script>

<ModalWrapper disabled={!symTitle} {isOpen} title={'Enter New Title'} on:close on:confirm={handleConfirm}>
  <input
    class="py-1 mb-4 dark:bg-gray-700 border-black dark:border-white border dark:text-white rounded-md focus:outline-none"
    bind:value={symTitle}
  />
</ModalWrapper>
