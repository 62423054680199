<script lang="ts">
  import { createFile, getFileUploadURL } from '$lib/api/messageThread';
  import AttachFileIcon from '$lib/assets/icons/attach-file-icon.svg';
  import { UploadStatus } from '$lib/enums/file';
  import type { SymFile } from '$lib/types/file';
  import { formatBytes } from '$lib/utils/memory-size-formatters';
  import { env } from '$src/env';
  import { toast } from '@zerodevx/svelte-toast';
  import axios from 'axios';
  import { createEventDispatcher } from 'svelte';

  export let messageThreadId: string;
  export let files: SymFile[];
  export let disabled = false;
  let filesToUpload: SymFile[] = [];
  let fileInput: HTMLInputElement;

  const dispatch = createEventDispatcher();
  const ACCEPTED_FILE_EXTENSIONS = ['csv'];

  const uploadFile = async (file: SymFile) => {
    const uploadedFile = { ...file };

    try {
      const { data: fileUploadData } = await getFileUploadURL(messageThreadId, uploadedFile.name);
      // TODO: blob.size can be used for validation
      await axios.put(fileUploadData.upload_url, uploadedFile.blob, {
        headers: {
          'Content-Type': 'image/png',
          'Content-Length': uploadedFile.blob.size.toString(),
        },
      });
      await createFile(fileUploadData.path, messageThreadId);
      // TODO: Think about cancelling an upload / memory leak
      uploadedFile.status = UploadStatus.Uploaded;
      uploadedFile.path = fileUploadData.path;
    } catch (e) {
      // TODO: Include loggly
      uploadedFile.status = UploadStatus.Failed;
      console.error(e);
    }
    dispatch('fileUploadFinished', uploadedFile);
  };

  $: if (filesToUpload?.length) {
    filesToUpload.forEach((file) => uploadFile(file));
    filesToUpload = [];
  }

  const handleFileUpload = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const fileList = target.files;
    if (fileList && fileList.length > 0) {
      const fileListArray = Array.from(fileList);
      // Checking if the size limit has been exceeded
      const attemptedFileSizeSum = [...files.map((f) => f.blob.size), ...fileListArray.map((f) => f.size)].reduce(
        (a, b) => a + b,
        0
      );

      if (attemptedFileSizeSum > env.fileSizeLimit) {
        toast.push(
          `Files too large: ${formatBytes(
            attemptedFileSizeSum
          )}. Please keep the uploaded files under the limit of ${formatBytes(env.fileSizeLimit)}`,
          { classes: ['error'] }
        );
        return;
      }
      const filesLength = files.length;
      const newFiles: SymFile[] = Array.from(fileList).map((file, index) => ({
        id: filesLength + index,
        name: file.name,
        status: UploadStatus.Uploading,
        blob: file,
      }));
      dispatch('fileListChange', [...files, ...newFiles]);
      filesToUpload = newFiles;
    }
  };
  const triggerFileUpload = () => {
    fileInput?.click();
  };
</script>

<button class="w-fit h-fit my-auto ml-2 cursor-pointer" {disabled} on:click={triggerFileUpload}>
  <img src={AttachFileIcon} alt="send" width="25vw" class="cursor-pointer" />
</button>
<input
  type="file"
  accept={ACCEPTED_FILE_EXTENSIONS.join(',')}
  multiple
  bind:this={fileInput}
  on:change={handleFileUpload}
  class="hidden"
  disabled={false}
/>
