<script lang="ts">
  export let messageInputHeight;
  export let message = '';

  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();
  const MESSAGE_INPUT_ROW_HEIGHT = 20;
  const MAX_MESSAGE_INPUT_HEIGHT = 120;

  function handleKeyDown(event: any) {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.stopPropagation();
      event.preventDefault();
      dispatch('sendMessage');
      resetMessageInputHeight(event.target);
    }
  }

  function handleKeyUp(event: any) {
    const messageInput = event.target;
    adjustMessageInputHeight(messageInput);
  }

  function adjustMessageInputHeight(textarea: HTMLTextAreaElement) {
    textarea.style.height = 'auto';
    messageInputHeight = textarea.scrollHeight;
    textarea.style.height = messageInputHeight + 'px';
    textarea.style.overflowY = textarea.scrollHeight > textarea.clientHeight ? 'auto' : 'hidden';
    if (messageInputHeight <= MAX_MESSAGE_INPUT_HEIGHT) {
      dispatch('updateChatDisplayHeight', messageInputHeight);
    }
  }

  function resetMessageInputHeight(textarea: HTMLTextAreaElement) {
    textarea.style.height = MESSAGE_INPUT_ROW_HEIGHT + 'px';
    textarea.style.overflowY = 'hidden';
  }
</script>

<div class="flex-grow flex items-center">
  <textarea
    on:keydown={handleKeyDown}
    on:keyup={handleKeyUp}
    bind:value={message}
    id="message"
    rows="1"
    class="bg-gray-200 dark:bg-gray-500 dark:text-white text-sm text-black block w-full disabled:opacity-[.5]
        resize-none overflow-y-auto my-1 max-h-[120px] border-none focus:ring-0"
    placeholder="Type a message..."
    required
  />
</div>
