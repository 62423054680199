<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  export let hasLoadMoreButton = true;
  const dispatch = createEventDispatcher();
</script>

{#if hasLoadMoreButton}
  <div class="flex flex-col items-center p-2 mt-5 hover:bg-gray-200 hover:rounded-full hover:dark:bg-ai8-sym-chat-gray">
    <button class="text-gray-700 dark:text-gray-200 font-semibold" on:click={() => dispatch('loadMore')}
      >Load more</button
    >
  </div>
{/if}
