<!-- svelte-ignore a11y-click-events-have-key-events -->
<script lang="ts">
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  export let disabled = false;
  let dropdownDiv: HTMLDivElement;

  onMount(() => {
    document.addEventListener('click', handleClickOutside);
  });

  onDestroy(() => {
    document.removeEventListener('click', handleClickOutside);
  });

  function handleClickOutside(event: Event) {
    if (!dropdownDiv.contains(event.target as Node)) {
      dispatch('closeDropdown');
    }
  }

  const dispatch = createEventDispatcher();
</script>

<div
  on:click={(e) => e.stopPropagation()}
  bind:this={dropdownDiv}
  class="absolute top-6 right-2 bg-gray-100 shadow-md border border-gray-300 rounded-md p-1 z-10 pl-3 pr-3 dark:bg-ai8-chat-gray dark:border-ai8-sym-chat-gray"
>
  <div
    class={`p-2 pr-5 pl-5 transition duration-300 ease-in-out ${
      disabled ? 'opacity-70' : 'cursor-pointer hover:bg-gray-200 hover:dark:bg-ai8-sym-chat-light-gray rounded-lg'
    }`}
    on:click={(e) => {
      e.stopPropagation();
      if (disabled) {
        return;
      }
      dispatch('handleDropdownClick', { action: 'edit' });
    }}
  >
    Rename
  </div>
  <div
    class="cursor-pointer p-2 pr-5 pl-5 transition duration-300 ease-in-out hover:bg-gray-200 hover:dark:bg-ai8-sym-chat-light-gray rounded-lg"
    on:click={(e) => {
      e.stopPropagation();
      dispatch('handleDropdownClick', { action: 'delete' });
    }}
  >
    Delete
  </div>
</div>
