import type { MessageFeedbackUpdateRequest } from '$lib/types/request';
import { ai8Api, taigaApi } from '$src/axios/api';

export const getUserMessages = async (params: any, projectId = '', userId?: number) => {
  return projectId
    ? taigaApi.get(
        `my/sym-chat-history/${projectId}/list-messages?session=${params.sessionId}&last_element_timestamp=${params.lastElementTimestamp}&page_size=${params.pageSize}&user_id=${userId}`
      )
    : ai8Api.get(
        `sym-chat-history/list-messages?session=${params.sessionId}&last_element_timestamp=${params.lastElementTimestamp}&page_size=${params.pageSize}`
      );
};

export const updateMessageFeedback = async (
  session_id: string,
  request: MessageFeedbackUpdateRequest,
  projectId = ''
) => {
  return projectId
    ? taigaApi.put(`my/sym-chat-history/${projectId}/give-feedback/?session=${session_id}`, request)
    : ai8Api.put(`sym-chat-history/give-feedback/?session=${session_id}`, request);
};

export const clearMessage = async (session_id: string, timestamp: number, projectId = '') => {
  return projectId
    ? taigaApi.put(`my/sym-chat-history/${projectId}/clear-message/?session=${session_id}`, { timestamp: timestamp })
    : ai8Api.put(`sym-chat-history/clear-message/?session=${session_id}`, { timestamp: timestamp });
};

export const clearSession = async (session_id: string, projectId = '') => {
  return projectId
    ? taigaApi.put(`my/sym-chat-history/${projectId}/clear-session/?session=${session_id}`)
    : ai8Api.put(`sym-chat-history/clear-session/?session=${session_id}`);
};

export const getAllSyms = async () => {
  return ai8Api.get(`sym-chat-history/syms`);
};
