<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  import MessageThread from '$lib/components/sym/MessageThread.svelte';
  import symStore, { handleChatMenuOpen } from '$lib/stores/symStore';
  import type { MessageThreadListResponse, SymResponse } from '$lib/types/response';
  import { isSmallScreen } from '$lib/utils/window';
  import { BarsSolid } from 'svelte-awesome-icons';
  import LoadMore from './LoadMore.svelte';

  export let availableSyms: SymResponse[] = [];
  export let messageThreads: MessageThreadListResponse[] = [];
  export let pageCursor: string | null = null;

  function onSelectSymFilter(e: Event) {
    const currentTarget = e.currentTarget as HTMLSelectElement;
    dispatch('selectSymFilter', {
      symId: currentTarget.value,
    });
  }

  const dispatch = createEventDispatcher();
  let smallScreen = isSmallScreen();
</script>

<div
  class="p-2 font-semibold {!smallScreen && $symStore?.isChatMenuOpen ? 'w-[17%] max-w-[17%]' : ''} {!smallScreen &&
  $symStore?.isChatMenuOpen
    ? ''
    : 'collapsed'}"
>
  {#if !smallScreen}
    <button on:click={handleChatMenuOpen} class="p-2 rounded-2xl hover:bg-gray-200">
      <BarsSolid size="17" class="border-none outline-none" />
    </button>
  {/if}
  {#if smallScreen || $symStore?.isChatMenuOpen}
    <div class="flex justify-between flex-col">
      <select
        class="bg-gray-200 dark:bg-gray-500 p-2 mt-3 cursor-pointer border rounded-2xl dark:bg-ai8-sym-chat-gray dark:border-gray-500"
        on:change={onSelectSymFilter}
      >
        <option value="">All Syms</option>
        {#each availableSyms as sym (sym.name)}
          <option value={sym.name}>{sym.display_name}</option>
        {/each}
      </select>
      <button
        class="pl-3 pb-1 mt-3 mb-3 flex items-center w-full md:w-29 rounded-full bg-gray-200 dark:bg-ai8-sym-chat-gray dark:border-ai8-sym-chat-gray"
        on:click={() => dispatch('newMessageThreadButtonClick')}
      >
        <div class="text-2xl flex justify-center items-center dark:text-white">+</div>
        <div class="dark:text-white mt-1 ml-3">New chat</div>
      </button>
    </div>
    <h1 class="ml-3 mt-6 mb-2">Recently</h1>
    <ul
      class="overflow-y-auto {smallScreen
        ? 'max-h-[calc(96dvh-25rem)] h-[calc(96dvh-25rem)]'
        : 'max-h-[calc(96dvh-20rem)] h-[calc(96dvh-20rem)]'}"
    >
      {#each messageThreads as messageThread (messageThread.id)}
        <MessageThread
          {messageThread}
          on:deleteMessageThread
          on:editMessageThreadTitle
          on:openMessageThread
          disabled={!availableSyms.some((s) => s.name === messageThread.sym)}
        />
      {/each}
      {#if pageCursor}
        <LoadMore on:loadMore />
      {/if}
    </ul>
  {/if}
</div>
