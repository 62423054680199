<script lang="ts">
  import { page } from '$app/stores';
  import { clearMessage, clearSession, getUserMessages, updateMessageFeedback } from '$lib/api/chat';
  import projectBoardStore from '$lib/stores/projectBoardStore';
  import userStore from '$lib/stores/userStore';
  import type { ChatMessage, ChatMessageResponse } from '$lib/types/message';
  import type { SymChatRequest } from '$lib/types/request';
  import type { SymChatResponse, SymResponse, User } from '$lib/types/response';
  import type { TaigaUser } from '$lib/types/user';
  import {
    AI_MESSAGE_TYPE,
    FEEDBACK,
    SOCKET_ERROR_MESSAGES,
    SOCKET_RESPONSE_CODES,
    USER_MESSAGE_TYPE,
    WEB_SOCKET_RECONNECT_COOLDOWN_SECONDS,
  } from '$lib/utils/constants';
  import { getNewMessageTimestamp } from '$lib/utils/date';
  import { isSmallScreen } from '$lib/utils/window';
  import { env } from '$src/env';
  import { createEventDispatcher, onMount } from 'svelte';
  import ChatDisplay from './ChatDisplay.svelte';
  import MessageInput from './MessageInput.svelte';

  export let isChatEnabled = false;
  export let selectedSessionId: string = '';
  export let selectedSym: SymResponse | null = null;

  let lastElementTimestamp: number | undefined;
  let messages: ChatMessage[] = [];
  let wsSocket: WebSocket;
  let loadingMessages: boolean = false;
  let botTyping = false;
  let socketErrorMessage: string | null = null;
  const dispatch = createEventDispatcher();
  let chatDisplayHeight = '80%';
  let messageInputHeight = 25;
  let isSmallComponent = isSmallScreen();
  let projectId = $page.params.id;

  $: isSmallComponent ||= $projectBoardStore.isProjectBoard;

  $: if (selectedSessionId) {
    messages = [];
    lastElementTimestamp = undefined;
    loadMessages(undefined, false);
  }

  function updateChatDisplayHeight(event: CustomEvent) {
    chatDisplayHeight = `calc(90% - ${event.detail}px)`;
  }

  onMount(async () => {
    startWebsocket();
  });

  const startWebsocket = () => {
    wsSocket = new WebSocket(env.symChatWsUrl);

    wsSocket.onopen = function (e) {
      console.log('[open] Connection to wss established');
    };

    wsSocket.onmessage = function (event) {
      handleResponse(event);
    };

    wsSocket.onclose = function (event) {
      if (event.wasClean) {
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        console.log(`[close] Connection forcefully closed, code=${event.code} reason=${event.reason}`);
        socketErrorMessage = SOCKET_ERROR_MESSAGES.connectionFailed;
      }
      setTimeout(startWebsocket, WEB_SOCKET_RECONNECT_COOLDOWN_SECONDS * 1000);
    };
  };

  function handleResponse(event: MessageEvent) {
    let response = JSON.parse(event.data) as SymChatResponse;
    if (response.statusCode === SOCKET_RESPONSE_CODES.OK) {
      addNewBotMessage(response);
    } else if (response.statusCode === SOCKET_RESPONSE_CODES.NO_CREDIT) {
      socketErrorMessage = SOCKET_ERROR_MESSAGES.outOfCredit;
    } else {
      const { message } = JSON.parse(event.data);
      console.log(`${response.statusCode} - ${message}`);
      socketErrorMessage = SOCKET_ERROR_MESSAGES.unknown;
    }
  }

  async function addNewBotMessage(response: SymChatResponse) {
    let lastMessage = messages.at(messages.length - 1);
    if (lastMessage && !lastMessage.complete) {
      lastMessage.message = lastMessage.message + response.body.content;
    } else {
      lastMessage = {
        message: response.body.content,
        timestamp: getNewMessageTimestamp(),
        type: AI_MESSAGE_TYPE,
        cleared: 'false',
        feedback: FEEDBACK.UNRECORDED_VALUE,
        sessionId: selectedSessionId ?? '',
        userId: getUserId(),
        showActions: false,
      } as ChatMessage;
      messages = [...messages, lastMessage];
    }
    if (response.body.message_complete) {
      await loadLatestMessage();
      lastMessage.complete = true;
      botTyping = false;
      const newMessage = messages[messages.length - 1].message;
      dispatch('handleEditMessageThreadTitle', {
        message: newMessage,
        symId: selectedSessionId,
      });
    } else {
      messages = [...messages];
    }
  }

  async function loadLatestMessage() {
    setTimeout(async () => {
      let { data } = await getUserMessages(
        {
          sessionId: selectedSessionId ?? '',
          lastElementTimestamp: '',
          pageSize: '1',
        },
        projectId,
        ($userStore.user as TaigaUser)?.ai8UserId
      );
      if (data) {
        const messagesResponse = data as ChatMessageResponse;
        // remove the message being compiled from chat partial responses
        messages.pop();
        // and take latest messages from db instead (pessimistic read)
        messages = [...messages, ...messagesResponse.chat_messages];
      }
    }, 1500);
  }

  async function messageFeedbackChangedHandler(messageFeedbackChangedEvent: CustomEvent) {
    let request = messageFeedbackChangedEvent.detail;
    if (request && $userStore.user) {
      await updateMessageFeedback(selectedSessionId, request, projectId);
    }
  }

  async function messageDeleteHandler(messageDeleteEvent: CustomEvent) {
    let timestamp = messageDeleteEvent.detail;
    if (timestamp && $userStore.user) {
      await clearMessage(selectedSessionId, timestamp, projectId);
      let message = messages.find((m) => m.timestamp === timestamp);
      if (message) {
        message.cleared = 'true';
        messages = [...messages];
      }
    }
  }

  async function clearSessionHandler() {
    if ($userStore.user) {
      socketErrorMessage = null;
      messages = [];
      lastElementTimestamp = undefined;
      await clearSession(selectedSessionId, projectId);
    }
  }

  function getUserId() {
    return (($userStore.user as TaigaUser)?.ai8UserId ?? ($userStore.user as User)?.id ?? '').toString();
  }

  function sendMessageHandler(event: CustomEvent) {
    if (event?.detail?.message && selectedSym) {
      let newUserMessage = {
        message: event.detail.message,
        dataFiles: event.detail?.dataFiles,
        timestamp: getNewMessageTimestamp(),
        type: USER_MESSAGE_TYPE,
        cleared: 'false',
        sessionId: selectedSessionId ?? '',
        userId: getUserId(),
        feedback: FEEDBACK.UNRECORDED_VALUE,
        showActions: true,
      } as ChatMessage;

      let emptyBotMessage = {
        message: '',
        timestamp: getNewMessageTimestamp(),
        type: AI_MESSAGE_TYPE,
        cleared: 'false',
        sessionId: selectedSessionId ?? '',
        userId: getUserId(),
        feedback: FEEDBACK.UNRECORDED_VALUE,
        showActions: false,
      } as ChatMessage;

      wsSocket.send(JSON.stringify(getMessageRequestFromMessage(newUserMessage.message, event.detail?.dataFiles)));
      messages = [...messages, newUserMessage, emptyBotMessage];
      botTyping = true;
    }
  }

  function getMessageRequestFromMessage(message: string, dataFiles: string[]): SymChatRequest {
    return {
      session_id: selectedSessionId ?? '',
      user_id: getUserId(),
      project_id: projectId ?? '',
      action: 'sym',
      message: message,
      data_files: dataFiles,
      context: '',
      request_type: 'message',
      response_type: 'stream',
      source: window.location.hostname,
      sym: selectedSym?.name,
    } as SymChatRequest;
  }

  async function loadMessages(_: CustomEvent | undefined, useTimestamp = true) {
    loadingMessages = true;
    let { data } = await getUserMessages(
      {
        sessionId: selectedSessionId ?? '',
        lastElementTimestamp: useTimestamp ? lastElementTimestamp?.toString() ?? '' : '',
        pageSize: '',
      },
      projectId,
      ($userStore.user as TaigaUser)?.ai8UserId
    );
    if (data) {
      const messagesResponse = data as ChatMessageResponse;

      messages = [...messages, ...messagesResponse.chat_messages].sort((x, y) => x.timestamp - y.timestamp);
      lastElementTimestamp = messagesResponse.last_element_timestamp;
      loadingMessages = false;
    }
  }
  $: if (messages) {
    messages.map((m) => console.log(`${m.message}, ${m.timestamp}`));
  }

  const chatClasses = ($userStore.user as TaigaUser)?.ai8UserId ? 'mb-4 max-h-[76%]' : 'mb-10 max-h-[80%]';
</script>

<div class={`mx-auto flex flex-col items-center justify-start ${chatClasses}`} style="height: {chatDisplayHeight};">
  <ChatDisplay
    hasLoadMoreButton={!!lastElementTimestamp}
    {messages}
    {socketErrorMessage}
    disabled={!selectedSym?.is_enabled}
    on:feedbackChange={messageFeedbackChangedHandler}
    on:delete={messageDeleteHandler}
    on:loadMore={loadMessages}
  />
</div>
<div class="mx-auto flex flex-row items-center {isSmallComponent ? 'w-[92%] mt-auto' : ' w-[72%]'}">
  <MessageInput
    messageThreadId={selectedSessionId}
    on:clearSession={clearSessionHandler}
    on:sendMessage={sendMessageHandler}
    on:updateChatDisplayHeight={updateChatDisplayHeight}
    {messageInputHeight}
    disabled={botTyping || socketErrorMessage !== null || isChatEnabled}
  />
</div>
