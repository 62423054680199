<script lang="ts">
  export let disabled = false;
  export let messages: ChatMessage[];
  export let socketErrorMessage: string | null = SOCKET_ERROR_MESSAGES.connectionFailed;
  export let hasLoadMoreButton: boolean;

  import SymBotIcon from '$lib/assets/icons/sym-bot-icon.svg';
  import projectBoardStore from '$lib/stores/projectBoardStore';
  import type { ChatMessage } from '$lib/types/message';
  import { DEFAULT_CHAT_LENGTH, SOCKET_ERROR_MESSAGES } from '$lib/utils/constants';
  import { isSmallScreen } from '$lib/utils/window';
  import ErrorMessage from './ErrorMessage.svelte';
  import LoadMore from './LoadMore.svelte';
  import MessageDisplay from './MessageDisplay.svelte';
  import ScrollContainer from './ScrollContainer.svelte';

  let chat: HTMLElement;
  let isSmallComponent = isSmallScreen();

  $: isSmallComponent ||= $projectBoardStore.isProjectBoard;
</script>

<div
  bind:this={chat}
  class="flex flex-grow flex-col {isSmallComponent ? 'max-h-full' : 'h-[80vh] pl-5'} w-full pr-1 overflow-x-hidden"
>
  {#if messages.length}
    <div class="ml-auto w-[30%] mx-auto">
      <div>
        <LoadMore {hasLoadMoreButton} on:loadMore />
      </div>
    </div>
    <div class="flex justify-center" style="overflow-y: scroll;">
      <div class="{isSmallComponent ? 'w-[95%]' : ' w-[70%]'} mt-2">
        <ScrollContainer defaultLength={DEFAULT_CHAT_LENGTH} divElement={chat} items={messages}>
          {#each messages as message (message.timestamp + message.type)}
            {#if message.cleared !== 'true'}
              <MessageDisplay {message} on:delete on:feedbackChange {disabled} />
            {/if}
          {/each}
        </ScrollContainer>
      </div>
    </div>
    <div class="ml-auto w-[30%] mx-auto">
      <div>
        <ErrorMessage errorMessage={socketErrorMessage ?? ''} />
      </div>
    </div>
  {:else}
    <div class="text-3xl text-center flex flex-grow flex-col justify-center items-center">
      <img src={SymBotIcon} alt="sym bot" class="mb-2 rounded-full" width="45px" height="45px" />
      <span class="text-2xl font-semibold text-gray-700">How can I help you today?</span>
    </div>
  {/if}
</div>
