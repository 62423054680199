<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  export let isOpen: boolean = false;
  export let disabled: boolean = false;
  export let title: string;
  export let confirmText: string = 'Confirm';
  let dialogElement: HTMLDialogElement;

  $: if (dialogElement) {
    if (isOpen) {
      dialogElement.showModal();
    } else {
      dialogElement.close();
    }
  }
  const dispatch = createEventDispatcher();
</script>

<div class="fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center">
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <dialog
    bind:this={dialogElement}
    on:close={() => dispatch('close')}
    on:click|self={() => dispatch('close')}
    class="p-0 rounded-xl dark:bg-ai8-chat-gray dark:border-ai8-sym-chat-gray dark:border"
  >
    <div class="w-88 md:w-96 p-8 shadow-md flex flex-col">
      <h1 class="mb-4 dark:text-white">{title}</h1>
      <slot />
      <div class="flex justify-end">
        <button class="p-2 mr-2 white:border rounded bg-slate-400 text-white" on:click={() => dispatch('close')}
          >Cancel</button
        >
        <button
          class={`p-2 white:border rounded bg-blue-500 text-white ${disabled ? 'bg-gray-500' : ''}`}
          {disabled}
          on:click={() => dispatch('confirm')}>{confirmText}</button
        >
      </div>
    </div>
  </dialog>
</div>
