<script lang="ts">
  export let errorMessage = '';
</script>

{#if errorMessage}
  <div
    class="bg-white min-h-fit flex flex-row pl-2 pr-10 py-4 rounded-xl items-center justify-center w-full text-red-400 border-[2px]"
  >
    {errorMessage}
  </div>
{/if}
