<script lang="ts">
  import type { ChatMessage } from '$lib/types/message';
  import { afterUpdate, onDestroy, onMount } from 'svelte';

  export let defaultLength = 16;
  export let divElement: HTMLElement;
  export let items: ChatMessage[];
  let lastScrollTop = 0;
  let shouldAutoScroll = true;

  const scrollHandler = () => {
    shouldAutoScroll = divElement.scrollTop >= lastScrollTop;
    lastScrollTop = divElement.scrollTop;
  };

  onMount(() => {
    if (divElement) {
      divElement.addEventListener('scroll', scrollHandler);
    }
  });

  onDestroy(() => {
    if (divElement) {
      divElement.removeEventListener('scroll', scrollHandler);
    }
  });

  afterUpdate(() => {
    if (items && items.length <= defaultLength && shouldAutoScroll) {
      scrollToBottom(divElement);
    }
  });

  $: if (items && divElement && shouldAutoScroll) {
    scrollToBottom(divElement);
  }

  const scrollToBottom = async (node: any) => {
    if (!node?.scroll) {
      return;
    }
    node.scroll({ top: node.scrollHeight, behavior: 'smooth' });
  };
</script>

<slot />
