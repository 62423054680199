<script lang="ts">
  import ModalWrapper from '$lib/components/generic/ModalWrapper.svelte';
  import type { SymResponse } from '$lib/types/response';
  import { createEventDispatcher } from 'svelte';
  export let availableSyms: SymResponse[] = [];
  export let isOpen: boolean = false;
  let selectedSym: string = '';

  const dispatch = createEventDispatcher();

  function handleSymSelect(e: Event) {
    const target = e?.currentTarget as HTMLSelectElement;
    selectedSym = target.value;
  }

  function handleConfirm() {
    dispatch('confirm', {
      sym: selectedSym,
      sym_display_name: availableSyms.find((s) => s.name === selectedSym)?.display_name,
    });
  }
</script>

<ModalWrapper
  disabled={!selectedSym}
  {isOpen}
  title={'Choose the Sym'}
  confirmText={'Create Thread'}
  on:close
  on:confirm={handleConfirm}
>
  <select
    class="mb-4 px-4 py-2 dark:bg-gray-500 border-black dark:border-white border dark:text-white rounded-md focus:outline-none"
    on:change={handleSymSelect}
  >
    <option value="">Please choose a Sym</option>
    {#each availableSyms as availableSym}
      <option value={availableSym.name}>{availableSym.display_name}</option>
    {/each}
  </select>
</ModalWrapper>
