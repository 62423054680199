<!-- svelte-ignore a11y-click-events-have-key-events a11y-no-noninteractive-element-interactions -->
<script lang="ts">
  import moreIcon from '$lib/assets/icons/more-icon.svg';
  import type { MessageThreadListResponse } from '$lib/types/response';
  import { createEventDispatcher } from 'svelte';
  import MessageThreadDropdown from './MessageThreadDropdown.svelte';

  const dispatch = createEventDispatcher();
  const ACTIONS: Record<string, () => void> = {
    delete: deleteMessageThread,
    edit: editMessageThreadTitle,
  };

  export let messageThread: MessageThreadListResponse;
  export let disabled = false;
  let showMenu: boolean = false;

  function handleCloseDropdown() {
    showMenu = false;
  }

  function handleMoreClick(e: Event) {
    e.stopPropagation();
    showMenu = !showMenu;
  }

  function handleDropdownClick(e: CustomEvent) {
    handleCloseDropdown();
    ACTIONS[e.detail.action]();
  }

  function deleteMessageThread() {
    dispatch('deleteMessageThread', {
      symId: messageThread.id,
    });
  }

  function editMessageThreadTitle() {
    dispatch('editMessageThreadTitle', {
      symId: messageThread.id,
    });
  }

  function onMessageThreadClick() {
    handleCloseDropdown();
    dispatch('openMessageThread', {
      symId: messageThread.id,
      symName: messageThread.sym,
    });
  }
</script>

<li
  class="rounded-xl p-4 pl-0 relative hover:bg-gray-200 hover:dark:bg-ai8-sym-chat-gray cursor-pointer"
  on:click={onMessageThreadClick}
>
  <div class="flex flex-col ml-3 mb-1">
    <span class="mb-1 text-xs">{messageThread.sym_display_name}</span>
    <span
      style="text-overflow: ellipsis; width: calc(100% - 2.5rem); max-width: calc(100% - 2.5rem);"
      class="dark:text-white truncate overflow-hidden"
    >
      {messageThread.title}
    </span>
  </div>
  <div class="absolute top-3.5 right-2 cursor-pointer">
    <div class="rounded-xl hover:light:bg-gray-200 hover:dark:bg-gray-700 p-1" on:click={handleMoreClick}>
      <img src={moreIcon} alt="more-icon.svg" class="w-3.5" />
    </div>
    {#if showMenu}
      <MessageThreadDropdown
        on:handleDropdownClick={handleDropdownClick}
        on:closeDropdown={handleCloseDropdown}
        {disabled}
      />
    {/if}
  </div>
</li>
