<script lang="ts">
  export let message: ChatMessage;
  export let disabled = false;
  import type { ChatMessage } from '$lib/types/message';
  import { AI_MESSAGE_TYPE } from '$lib/utils/constants';
  import { formatMessageTimeStamp } from '$lib/utils/date';
  import { isSmallScreen } from '$lib/utils/window';
  import { createEventDispatcher } from 'svelte';
  import { TrashCanRegular } from 'svelte-awesome-icons';
  import FeedbackOptions from './FeedbackOptions.svelte';

  let smallScreen = isSmallScreen();

  const dispatch = createEventDispatcher();
  let fromBot = message.type === AI_MESSAGE_TYPE;
</script>

<div class="flex {smallScreen ? 'flex-col' : 'flex-row'} items-center justify-between w-fit">
  {#if fromBot && !smallScreen}
    <FeedbackOptions bind:message on:feedbackChange {disabled} />
  {/if}
  <div class="flex flex-row items-center">
    {#if !smallScreen}
      <span class="text-ai8-dark-gray text-sm/[15px] text-right">
        {formatMessageTimeStamp(message.timestamp)}
      </span>
    {/if}
    <button
      class="ml-3 dark:text-gray-300"
      disabled={message.showActions === false || disabled}
      on:click={() => dispatch('delete', message.timestamp)}><TrashCanRegular size="15" /></button
    >
  </div>
</div>
