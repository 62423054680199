<script lang="ts">
  export let disabled = false;
  export let messageInputHeight = 25;
  export let messageThreadId: string;

  import SendRightArrowIcon from '$lib/assets/icons/send-arrow-icon.svg';
  import { UploadStatus } from '$lib/enums/file';
  import projectBoardStore from '$lib/stores/projectBoardStore';
  import userStore from '$lib/stores/userStore';
  import type { SymFile } from '$lib/types/file';
  import { SymPermissions } from '$lib/utils/constants';
  import { isSmallScreen } from '$lib/utils/window';
  import { createEventDispatcher } from 'svelte';
  import ChatFileList from './ChatFileList.svelte';
  import ChatFileUploader from './ChatFileUploader.svelte';
  import MessageTextInput from './MessageTextInput.svelte';

  const dispatch = createEventDispatcher();

  let files: SymFile[] = [];
  let message: string = '';
  let isSmallComponent = isSmallScreen();

  $: isSmallComponent ||= $projectBoardStore.isProjectBoard;

  $: if (messageThreadId) {
    files = [];
  }

  function dispatchMessage() {
    const dataFiles = files.map((f) => f.path ?? '');
    if (disabled || !areFilesUploaded()) {
      return;
    }
    if (message != '') {
      dispatch('sendMessage', { message: message, dataFiles: dataFiles });
      files = [];
      message = '';
    }
  }

  function onFileUploadFinished(event: CustomEvent) {
    const finishedFile = event.detail;
    files = files.map((file) => {
      if (file.id === finishedFile.id && file.name === finishedFile.name) {
        return finishedFile;
      }
      return file;
    });
  }

  function handleFileDelete(event: CustomEvent) {
    files = files
      .filter((file) => file.id !== event.detail)
      .map((file, index) => {
        if (file.id >= index) {
          return {
            ...file,
            id: file.id - 1,
          };
        }
        return file;
      });
  }

  function setFiles(event: CustomEvent) {
    files = event.detail;
  }

  function areFilesUploaded() {
    return files.every((f) => f.status === UploadStatus.Uploaded);
  }
</script>

<div class="w-full flex flex-col {isSmallComponent ? 'px-2' : 'pr-9 pl-16'}">
  <div class="flex w-full">
    {#if files?.length}
      <ChatFileList {files} on:fileDelete={handleFileDelete} />
    {/if}
  </div>
  <div class="w-full flex flex-row bg-gray-200 dark:bg-gray-500 rounded-2xl px-7 py-2 flex-grow">
    {#if !$projectBoardStore.isProjectBoard && $userStore?.user?.permissions?.includes(SymPermissions.CAN_USE_MESSAGE_THREAD_FILES)}
      <ChatFileUploader
        {messageThreadId}
        {files}
        on:fileUploadFinished={onFileUploadFinished}
        on:fileListChange={setFiles}
        {disabled}
      />
    {/if}
    <MessageTextInput
      bind:messageInputHeight
      bind:message
      on:sendMessage={dispatchMessage}
      on:updateChatDisplayHeight
    />
    <button
      class="w-fit h-fit my-auto bg-ai8-light-black p-3 rounded-2xl ml-2 disabled:opacity-[.5] dark:bg-ai8-navy-gray"
      on:click={() => dispatchMessage()}
      disabled={disabled || !areFilesUploaded()}
    >
      <img src={SendRightArrowIcon} alt="send" width="15vw" />
    </button>
  </div>
</div>